import { getStorageData } from '../storage';
import { WALLEY_STORAGE_KEY } from './constants';

export const getWalleyStorage = () => {
    try {
        return getStorageData(WALLEY_STORAGE_KEY) ?? {};
    } catch (error) {
        return {};
    }
};
