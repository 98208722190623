import { Token } from './Token';
import { parseToken } from './parseToken';

export const isBitSetInToken = (
    token: Token,
    position: number,
    bitmask: number,
    defaultValue?: boolean
) => {
    const { ref } = parseToken(token);

    if (!ref || ref.length <= position) {
        return defaultValue;
    }

    // eslint-disable-next-line no-bitwise
    return (parseInt(ref.charAt(position), 16) & bitmask) === bitmask;
};
