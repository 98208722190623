import { isArray } from '../array';
import { isEmptyValue } from '../object';
import { isObject } from '../object/isObject';

const safeAccess = (
    value: Record<string, unknown>,
    key: string,
    throwError: boolean | undefined
) => {
    try {
        const prop = value[key];

        if (prop === window) {
            return '[WINDOW]';
        }

        if ('Node' in window && prop instanceof Node) {
            return `[${prop.nodeName || 'NODE'}]`;
        }

        return prop;
    } catch (error) {
        if (throwError) {
            throw error;
        }
        return error?.toString();
    }
};

export const jsonStringify = (
    objectValue: unknown,
    format?: boolean,
    throwError?: boolean
) => {
    const seen: unknown[] = [];

    return JSON.stringify(
        objectValue,
        (_, value) => {
            if (isEmptyValue(value)) {
                return undefined;
            }

            if (!isObject(value)) {
                return value;
            }

            if (seen.indexOf(value) !== -1) {
                return undefined;
            }

            seen.push(value);

            if (isArray(value)) {
                return value;
            }

            if (value instanceof Error) {
                return {
                    ...value,
                    name: value.name,
                    message: value.message,
                    stack: value.stack,
                };
            }

            return Object.keys(value).reduce(
                (result, key) => {
                    // eslint-disable-next-line no-param-reassign
                    result[key] = safeAccess(value, key, throwError);
                    return result;
                },
                {} as Record<string, unknown>
            );
        },
        format ? '    ' : undefined
    );
};
