import { MerchantEventType, Message, MessageType } from '@walley/domain';
import { toObject } from '@walley/language';
import { HandleMessageFunction } from './HandleMessageFunction';
import { handleMerchantEventMessage } from './handleMerchantEventMessage';

const getType = ({ type }: Message): MerchantEventType | undefined => {
    switch (type) {
        case MessageType.CustomerUpdated:
            return MerchantEventType.CustomerUpdated;
        case MessageType.Locked:
            return MerchantEventType.Locked;
        case MessageType.Unlocked:
            return MerchantEventType.Unlocked;
        case MessageType.Resumed:
            return MerchantEventType.Resumed;
        case MessageType.ShippingUpdated:
            return MerchantEventType.ShippingUpdated;
        case MessageType.PurchaseCompleted:
            return MerchantEventType.PurchaseCompleted;
        case MessageType.PaymentAborted:
            return MerchantEventType.PaymentAborted;
        case MessageType.CrmUpdated:
            return MerchantEventType.CrmUpdated;
        case MessageType.MerchantValidationFailed:
            return MerchantEventType.OrderValidationFailed;
        default:
            return undefined;
    }
};

export const handleOldMerchantEventMessage: HandleMessageFunction = (
    message,
    context
) =>
    handleMerchantEventMessage(
        {
            ...message,
            value: {
                type: getType(message),
                payload: toObject(message.value),
            },
        },
        context
    );
