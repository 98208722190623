import { toUri } from './toUri';

export const getUriOrigin = <T extends string | undefined>(uri: T) => {
    try {
        const url = new URL(toUri(uri) ?? '');
        return toUri(url.origin);
    } catch (error) {
        return undefined;
    }
};
