import { isBitSetInToken, parseToken } from '@walley/domain';
import { DeepPartial, Uri, appendUriQuery, concatUri } from '@walley/language';
import { getLocationUri } from '../dom';
import { InitIntegrationData } from './InitIntegrationData';
import { IntegrationOverrides } from './IntegrationOverrides';
import { VARIANT_B2B, VARIANT_B2C, VARIANT_WIDGET } from './constants';
import { getClientUri } from './getClientUri';
import { getWalleyStorage } from './getWalleyStorage';

const getVariantPath = (data: InitIntegrationData) => {
    if (data.widget) {
        return concatUri(VARIANT_WIDGET, encodeURIComponent(data.widget));
    }

    if (!data.token) return '';

    return isBitSetInToken(data.token, 0, 1) ? VARIANT_B2B : VARIANT_B2C;
};

// Used when making changes that could break integration between loader and
// other parts during rollout. Could be something like this:
// ({
//     onBeforePayment: true,
// });
// This is passed to the integration so the integration knows what features are
// available in this version of the loader so that integration can adapt to it.
const getFeatures = () => undefined;

const getWidgetClientUri = (
    clientHost: Uri,
    path: string,
    overrides: DeepPartial<IntegrationOverrides> | undefined
) => {
    const pathParts = path.split('/');
    const name = pathParts.shift() || '';
    return concatUri(getClientUri(clientHost, name, overrides), ...pathParts);
};

export const getIntegrationUri = (data: InitIntegrationData) => {
    const path = concatUri(getVariantPath(data), data.token);

    const clientUri = getWidgetClientUri(data.clientHost, path, data.overrides);

    return appendUriQuery(clientUri, {
        data: data.data,
        lang: data.lang
            ? `${data.lang.split('-')[0]}-${parseToken(data.token)?.country}`
            : undefined,
        parentUrl: getLocationUri(),
        debug: data.debug,
        payLink: data.payLink,
        rememberMeOptOut: data.rememberMeOptOut,
        showCart: data.showCart,
        webView: data.webView,
        actionColor: data.actionColor,
        actionTextColor: data.actionTextColor,
        amount: data.amount,
        overrides: data.overrides,
        features: getFeatures(),
        ...getWalleyStorage(),
    });
};
