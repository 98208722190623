import { toToken } from '@walley/domain';
import {
    InitIntegrationData,
    IntegrationOverrides,
    getDocumentLanguage,
} from '@walley/interaction';
import {
    Uri,
    isNonWhitespaceString,
    jsonParse,
    stringToBoolean,
    toNumber,
} from '@walley/language';
import { LoaderScriptAttributes } from './LoaderScriptAttributes';

export const loaderScriptAttributesToInitData = (
    attributes: LoaderScriptAttributes,
    clientHost: Uri
): InitIntegrationData | undefined => {
    const token = toToken(attributes.token);
    return token
        ? {
              token,
              padding: attributes.padding !== 'none',
              containerId: isNonWhitespaceString(attributes.containerId)
                  ? attributes.containerId
                  : undefined,
              widget: attributes.widget,
              version: attributes.version,
              lang: attributes.lang || getDocumentLanguage(),
              headless: stringToBoolean(attributes.headless),
              debug: stringToBoolean(attributes.debug),
              payLink: stringToBoolean(attributes.payLink),
              rememberMeOptOut: stringToBoolean(attributes.rememberMeOptOut),
              showCart: stringToBoolean(attributes.showCart),
              webView: stringToBoolean(attributes.webView),
              actionColor: attributes.actionColor,
              actionTextColor: attributes.actionTextColor,
              amount: toNumber(attributes.amount),
              clientHost,
              overrides: jsonParse<IntegrationOverrides>(attributes.overrides),
          }
        : undefined;
};
