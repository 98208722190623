/* eslint-disable no-underscore-dangle */
import { MessageType } from '@walley/domain';
import { DeepPartial } from '@walley/language';
import { InitIntegrationModalData } from '../InitIntegrationModalData';
import { appendIntegrationModalIframe } from '../appendIntegrationModalIframe';
import { getIntegrationModalIframe } from '../getIntegrationModalIframe';
import { removeIntegrationModalIframe } from '../removeIntegrationModalIframe';
import { HandleMessageFunction } from './HandleMessageFunction';
import { sendMessageToIntegrationIframes } from './sendMessageToIntegrationIframes';

export const handleShowModalMessage: HandleMessageFunction = (
    { token, value, sourceId },
    context
) => {
    if (!token) return;

    const data = value as DeepPartial<InitIntegrationModalData>;

    const sendModalShown = () => {
        sendMessageToIntegrationIframes(
            {
                type: MessageType.ModalShown,
                value: data?.widget,
                targetId: sourceId,
            },
            context
        );
    };

    const existingIframe = getIntegrationModalIframe();

    if (existingIframe) {
        if (
            existingIframe.dataset.modal === data?.widget &&
            existingIframe.dataset.token === data?.token
        ) {
            existingIframe.focus();
            sendModalShown();
            return;
        }

        removeIntegrationModalIframe(existingIframe);
    }

    const iframe = appendIntegrationModalIframe(
        context.clientHost,
        data,
        sendModalShown
    );

    iframe.focus();
};
