import { getAttribute } from '@walley/interaction';
import { LoaderScriptAttributes } from './LoaderScriptAttributes';
import { getTokenAttribute } from './getTokenAttribute';

export const loaderAttributeInitialized = 'data-initialized';

export const getLoaderScriptAttributes = (
    script: HTMLScriptElement
): LoaderScriptAttributes => ({
    token: getTokenAttribute(script),
    version: getAttribute(script, 'data-version'),
    lang: getAttribute(script, 'data-lang', 'lang'),
    widget: getAttribute(script, 'data-widget'),
    debug: getAttribute(script, 'data-debug'),
    rememberMeOptOut: getAttribute(script, 'data-remember-me-opt-out'),
    containerId: getAttribute(script, 'data-container-id'),
    padding: getAttribute(script, 'data-padding'),
    actionColor: getAttribute(script, 'data-action-color'),
    actionTextColor: getAttribute(script, 'data-action-text-color'),
    amount: getAttribute(script, 'data-amount'),
    payLink: getAttribute(script, 'data-paylink'),
    showCart: getAttribute(script, 'data-showcart'),
    headless: getAttribute(script, 'data-headless'),
    webView: getAttribute(script, 'data-webview'),
    initialized: getAttribute(script, loaderAttributeInitialized),
    overrides: getAttribute(script, 'data-overrides'),
});
