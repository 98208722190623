/* eslint-disable no-underscore-dangle */
import {
    LogEntry,
    MessageLogEntry,
    MessageType,
    MessageValue,
} from '@walley/domain';
import {
    ErrorLike,
    isFunction,
    isPromise,
    toErrorLike,
    toObject,
} from '@walley/language';
import { HandleMessageFunction } from './HandleMessageFunction';
import { sendMessageToIntegrationIframes } from './sendMessageToIntegrationIframes';

export const handleOnBeforePaymentMessage: HandleMessageFunction = (
    message,
    context
) => {
    const sendResponse = <T extends MessageValue>(
        type: MessageType,
        payload?: T
    ) =>
        sendMessageToIntegrationIframes(
            {
                ...message,
                targetId: message.sourceId,
                sourceId: undefined,
                type,
                value: payload,
            },
            context
        );

    const sendErrorResponse = (error?: ErrorLike) =>
        sendResponse(
            MessageType.OnBeforePaymentError,
            error
                ? ({
                      ...toObject<ErrorLike>(error),
                      // Need to override here because props on error is not
                      // picked up by Object.keys
                      message: error?.message,
                      name: error?.name,
                      stack: error?.stack,
                      cause: error?.cause,
                  } as MessageValue)
                : undefined
        );

    const sendSuccessResponse = () =>
        sendResponse(MessageType.OnBeforePaymentSuccess);

    const logOnBeforePayment = (entry: Partial<LogEntry>) => {
        context.log.info({
            ...entry,
            messageTemplate: `onBeforePayment: ${
                (entry as MessageLogEntry).messageTemplate
            }`,
            moduleName: 'handleOnBeforePaymentRequest',
            properties: {
                ...entry.properties,
                publicToken: message.token,
            },
        });
    };

    // eslint-disable-next-line no-underscore-dangle
    const { onBeforePayment } = context.walley.checkout._state;

    if (!isFunction(onBeforePayment)) {
        logOnBeforePayment({
            messageTemplate:
                'No callback registered (returning success to iframe)',
        });
        sendSuccessResponse();
        return;
    }

    let promise;

    try {
        promise = onBeforePayment();
    } catch (exception) {
        logOnBeforePayment({
            messageTemplate:
                'Exception when calling callback (returning error to iframe): {ExceptionMessage}',
            messageTemplateParameters: [toErrorLike(exception)?.message],
            exception,
        });
        sendErrorResponse();
        return;
    }

    // Quick check that a promise was returned.
    if (!isPromise(promise)) {
        logOnBeforePayment({
            messageTemplate:
                'Callback did not return a promise (returning error to iframe)',
        });
        sendErrorResponse();
        return;
    }

    try {
        promise
            .then(() => {
                sendResponse(MessageType.OnBeforePaymentSuccess);
            })
            .catch(err => {
                sendErrorResponse(err);
            });
    } catch (_) {
        sendErrorResponse();
    }
};
