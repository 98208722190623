export enum MessageType {
    Relay = 'relay',
    Broadcast = 'broadcast',
    MerchantEvent = 'merchant-event',
    HeightSet = 'height-set',
    Redirect = 'redirect',
    RedirectFail = 'redirect-fail',
    Update = 'update',
    LanguagePref = 'language-pref',
    TrustlyCancel = 'trustly-cancel',
    TrustlyLoadTimeout = 'trustly-load-timeout',
    TrustlySetAccount = 'trustly-set-account',
    PaymentIqLoadTimeout = 'paymentiq-load-timeout',
    PaymentIqCancel = 'paymentiq-cancel',
    SubmitCommitmentId = 'submit-commitment-id',
    CancelCommitmentIdInput = 'cancel-commitment-id-input',
    SwedishBankIdCancel = 'swedish-bank-id-cancel',
    PaymentIqCardValidateForm = 'paymentiq-card-validate-form',
    PaymentIqCardFormValidationError = 'paymentiq-card-validation-error',
    PaymentIqCardFormValidated = 'paymentiq-card-validated',
    PaymentIqCardFormLoaded = 'paymentiq-card-form-loaded',
    PaymentIqCardValuesChanged = 'paymentiq-card-values-changed',
    PaymentIqCardResult = 'paymentiq-card-verification-result',
    PaymentIqCardCancel = 'paymentiq-card-verification-cancel',
    PaymentStatus = 'payment-status',
    PaymentMethodSelected = 'payment-method-selected',
    PaymentOptionSelected = 'payment-option-selected',
    ShowModal = 'modal',
    HideModal = 'hide-modal',
    ModalLoaded = 'modal-loaded',
    ModalShown = 'modal-shown',
    AbortHideModal = 'abort-hide-modal',
    ModalLoadError = 'modal-load-error',
    HideModalInState = 'hide-modal-in-state',
    StoreParameter = 'StoreParameter',
    Locked = 'locked',
    Unlocked = 'unlocked',
    Resumed = 'resumed',
    CrmUpdated = 'crm-updated',
    CustomerUpdated = 'customer-updated',
    ShippingUpdated = 'shipping-updated',
    MerchantValidationFailed = 'merchant-validation-failed',
    PurchaseCompleted = 'purchase-completed',
    PaymentAborted = 'payment-aborted',
    DeliverySet = 'delivery-set',
    DeliveryCancel = 'delivery-cancel',
    DeliveryError = 'delivery-error',
    DeliveryChoices = 'delivery-choices',
    DeliveryModalLoaded = 'delivery-choices-modal-loaded',
    KalpSet = 'kalp-set',
    KalpApproved = 'kalp-approved',
    KalpCancel = 'kalp-cancel',
    KalpError = 'kalp-error',
    WidgetLoaded = 'widget-loaded',
    SwishCancel = 'swish-cancel',
    Suspend = 'suspend',
    Resume = 'resume',
    BeginPayment = 'begin-payment',
    PrivateAddressSubmit = 'b2c-address-submit',
    PrivateAddressCancel = 'b2c-address-cancel',
    PrivateAddressError = 'b2c-address-error',
    BusinessInvoiceEmailSubmit = 'b2b-invoice-email-submit',
    BusinessInvoiceEmailCancel = 'b2b-invoice-email-cancel',
    BusinessInvoiceEmailError = 'b2b-invoice-email-error',
    BusinessDeliveryAddressSubmit = 'b2b-delivery-address-submit',
    BusinessDeliveryAddressCancel = 'b2b-delivery-address-cancel',
    BusinessDeliveryAddressError = 'b2b-delivery-address-error',
    BusinessInvoiceAddressSubmit = 'b2b-invoice-address-submit',
    BusinessInvoiceAddressCancel = 'b2b-invoice-address-cancel',
    BusinessInvoiceAddressError = 'b2b-invoice-address-error',
    BusinessEditBuyerModalSubmit = 'b2b-edit-buyer-modal-submit',
    BusinessEditBuyerModalError = 'b2b-edit-buyer-modal-error',
    OnBeforePayment = 'on-before-payment-request',
    OnBeforePaymentError = 'on-before-payment-error',
    OnBeforePaymentSuccess = 'on-before-payment-success',
    GetExistingEngagementFromEmailAndPhone = 'get-existing-engagement-from-email-and-phone',
    UpdateIdentificationData = 'update-identification-data',
    FindEngagementByRegistrationNumber = 'find-engagement-by-registration-number',
    SaveAddress = 'save-address',
    RememberCustomer = 'remember-customer',
    ResetCustomerIdentificationState = 'reset-customer-identification-state',
    SwapCustomerComplement = 'swap-customer-complement',
    SimplifiedTermsClose = 'simplified-terms-close',
    IdpCancel = 'idp-cancel',
    IdpRedirect = 'idp-redirect',
    SelectInstallmentPaymentMethodOption = 'SelectInstallmentPaymentMethodOption',
    Ready = 'ready',
    NoticeClose = 'notice-close',
    ApplyTestData = 'apply-test-data',
}
