/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { Log, LogLevel, LogProperties, WalleyGlobal } from '@walley/domain';
import { callOptionalFunction, truncateString } from '@walley/language';

type Context = {
    walley: WalleyGlobal;
    log: Log;
    isInternalScript: (src: string) => boolean;
    logPropertiesEnricher: () => LogProperties;
};
export const registerIntegrationErrorHandler = ({
    walley,
    log,
    isInternalScript,
    logPropertiesEnricher,
}: Context) => {
    callOptionalFunction(walley.checkout._state.unregisterErrorHandler);

    const onWindowError = (event: ErrorEvent) => {
        if (!event) {
            return;
        }

        const requestUri = event.filename;

        const exception = event.error || {
            name: 'EventError',
            message: event.message,
            stack: requestUri
                ? `${requestUri}:${event.lineno}:${event.colno}`
                : undefined,
        };

        const internalError = isInternalScript(requestUri);
        const logLevel = internalError ? LogLevel.Error : LogLevel.Information;

        log.log(logLevel, {
            messageTemplate: internalError
                ? '{ExceptionMessage}'
                : "An error on merchant's site occurred: {ExceptionMessage}",
            messageTemplateParameters: [
                truncateString(
                    exception.message || 'Unknown error occurred',
                    1000
                ),
            ],
            properties: {
                requestUri,
                ...logPropertiesEnricher(),
            },
            exception,
            moduleName: 'registerIntegrationErrorHandler',
            functionName: 'onWindowError',
        });
    };

    window.addEventListener('error', onWindowError);

    walley.checkout._state.unregisterErrorHandler = () => {
        window.removeEventListener('error', onWindowError);
        walley.checkout._state.unregisterErrorHandler = undefined;
    };
};
