import { toErrorLike } from '../error';
import { isFunction } from '../function';
import { PlainObject } from './PlainObject';
import { isObject } from './isObject';
import { isPlainObject } from './isPlainObject';
import { toObject } from './toObject';

const toPlainObjectValue = (value: unknown) => {
    if (isFunction(value)) {
        return undefined;
    }

    if (isObject(value)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return toPlainObject(value);
    }

    return value;
};

const toPlainObjectInner = (obj: object) => {
    const plainObj: Record<string, unknown> = {};

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (let key in obj) {
        try {
            plainObj[key.toString()] = toPlainObjectValue(
                obj[key as keyof typeof obj]
            );
        } catch (err) {
            plainObj[key.toString()] = toErrorLike(err)?.message;
        }
    }

    return plainObj;
};

export const toPlainObject = (value: unknown): PlainObject | undefined => {
    const obj = toObject(value);

    if (!obj) {
        return undefined;
    }

    if (isPlainObject(obj)) {
        return obj;
    }

    return toPlainObjectInner(obj);
};
