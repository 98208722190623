import {
    EventLogEntry,
    ExceptionLogEntry,
    Log,
    LogConfig,
    LogContext,
    LogEntry,
    LogLevel,
    LogProperties,
    MessageLogEntry,
    PublicToken,
    Token,
    isLogLevelAbove,
    normalizeLogProperties,
    normalizeMessageTemplateParameters,
    renderMessageTemplate,
    renderMessageTemplateParameter,
    resolveErrorStack,
    toToken,
} from '@walley/domain';
import {
    Uri,
    callOptionalFunction,
    isNonEmptyString,
    isSet,
    toErrorLike,
    truncateString,
    withoutEmptyValues,
} from '@walley/language';
import { ApiPostPayload } from '../api/ApiPostPayload';
import { apiPostRaw } from '../api/apiPostRaw';
import { PostEventRequest } from './PostEventRequest';
import { PostLogRequest } from './PostLogRequest';
import { createLogContext } from './createLogContext';
import { logClient } from './logClient';

const unknownErrorName = 'UnknownError';
const unknownErrorMessage = 'Unknown error occurred';
const logTimeout = 100_000;
const duplicateLogSlidingTimeout = 1_000;

const resolveException = async (
    entry: Partial<ExceptionLogEntry>
): Promise<Error | undefined> => {
    const exception = toErrorLike(entry.exception);
    return exception
        ? {
              ...exception,
              message: truncateString(
                  exception.message?.trim() || unknownErrorMessage,
                  1000
              ),
              name: exception.name?.trim() || unknownErrorName,
              stack: await resolveErrorStack(exception),
              cause: exception.cause,
          }
        : undefined;
};

const getMessageTemplate = (
    { messageTemplate, messageTemplateParameters }: Partial<MessageLogEntry>,
    exception?: Error
) =>
    !isSet(messageTemplate) && isNonEmptyString(exception?.message)
        ? {
              messageTemplate: '{ExceptionMessage}',
              messageTemplateParameters: [exception?.message],
          }
        : {
              messageTemplate: renderMessageTemplateParameter(
                  messageTemplate,
                  false
              ),
              messageTemplateParameters,
          };

const post = async (payload: ApiPostPayload<Record<string, unknown>>) => {
    const body = withoutEmptyValues(payload.body);

    if (!body) {
        return;
    }

    try {
        // Could have been better to use navigator.sendBeacon but it was blocked by uBlock
        const response = await apiPostRaw({
            ...payload,
            path: `checkout/${payload.path}`,
            body,
            timeout: logTimeout,
            keepalive: true,
        });

        await response.text(); // Only to avoid pipe error in chromium when not reading stream to end
    } catch (error) {
        // Do nothing as we don't actually care
    }
};

const getRequestPayload = (request: PostLogRequest | PostEventRequest) => ({
    body: {
        ...request,
        messageTemplateParameters: normalizeMessageTemplateParameters(
            request.messageTemplateParameters
        ),
        properties: normalizeLogProperties(request.properties),
    },
    publicToken: request.properties?.publicToken as PublicToken | undefined,
    isWebView: request.properties?.isWebView,
    isPayLink: request.properties?.isPayLink,
});

const logServerInternal = (apiHost: Uri, postLogRequest: PostLogRequest) => {
    post({
        ...getRequestPayload(postLogRequest),
        apiHost,
        path: 'log',
    });
};

const getSourceContext = (
    sourceSystem: string,
    ...parts: (string | undefined)[]
) =>
    [sourceSystem, ...parts]
        .map(v => v?.trim())
        .filter(v => v)
        .join('.') || undefined;

const getLogProperties = (
    context: LogContext,
    moduleName: string,
    functionName?: string,
    properties?: LogProperties
): LogProperties => {
    context.sequenceNumber += 1;

    const {
        version,
        commit,
        machineName,
        sourceSystem,
        country,
        isPayLink,
        isWebView,
        parentUrl,
        token,
    } = context.config;

    return {
        clientLogSequence: context.sequenceNumber,
        clientTimestamp: new Date().toISOString(),
        sourceSystemInformationalVersion: version,
        gitCommitHash: commit,
        machineName,
        referrer: window.location?.href,
        sourceSystem,
        countryCode: country,
        isPayLink,
        isWebView,
        parent: parentUrl,
        publicToken: token,
        sourceContext: getSourceContext(sourceSystem, moduleName, functionName),
        ...callOptionalFunction(context.enricher),
        ...properties,
    };
};

const getApiHost = ({
    config: { apiHost },
    apiHostOverrides,
    lastLogToken,
}: LogContext) => {
    return (
        (lastLogToken ? apiHostOverrides[lastLogToken] : undefined) || apiHost
    );
};

const logDuplicateLogRequests = (context: LogContext) => {
    const { logLevelClient, console } = context.config;
    if (context.duplicateMessageCount > 0) {
        const postLogRequest: PostLogRequest = {
            level: LogLevel.Information,
            messageTemplate: 'Did not send {Count} duplicate(s) of:\n{Message}',
            messageTemplateParameters: [
                context.duplicateMessageCount,
                context.lastLogString.split('|')[2],
            ],
            properties: getLogProperties(
                context,
                'logService',
                'logDuplicateLogRequests'
            ),
        };

        context.duplicateMessageCount = 0;

        logClient(postLogRequest, { logLevelClient, console });
        logServerInternal(getApiHost(context), postLogRequest);
    }
};

const renderLogString = (postLogRequest: PostLogRequest): string => {
    const message = renderMessageTemplate(
        postLogRequest.messageTemplate,
        postLogRequest.messageTemplateParameters
    );
    const exception = postLogRequest.exception
        ? `${postLogRequest.exception.name}: ${postLogRequest.exception.message}\n${postLogRequest.exception.stack}`
        : '';

    return [
        postLogRequest.level,
        postLogRequest.properties?.sourceContext,
        message,
        exception,
    ].join('|');
};

const isDuplicateLogRequest = (
    context: LogContext,
    postLogRequest: PostLogRequest
): boolean => {
    const logString = renderLogString(postLogRequest);
    const timestamp = Date.now();

    if (
        context.lastLogString === logString &&
        timestamp - context.lastLogTimestamp < duplicateLogSlidingTimeout
    ) {
        context.duplicateMessageCount += 1;
        context.lastLogTimestamp = timestamp; // Make sliding timeout
        return true;
    }

    return false;
};

const logServer = (
    context: LogContext,
    postLogRequest: PostLogRequest,
    allowDuplicates: boolean
) => {
    const { logLevelServer } = context.config;
    if (
        !isLogLevelAbove(postLogRequest.level, logLevelServer) ||
        (!allowDuplicates && isDuplicateLogRequest(context, postLogRequest))
    ) {
        return;
    }

    logDuplicateLogRequests(context);

    context.lastLogString = renderLogString(postLogRequest);
    context.lastLogTimestamp = Date.now();
    context.lastLogToken = toToken(postLogRequest.properties?.publicToken);

    logServerInternal(getApiHost(context), postLogRequest);
};

// TODO: Should remove async here, but can't until backend resolves exception with source maps instead of client doing the heavy lifting
const log = async (
    context: LogContext,
    level: LogLevel,
    entry: LogEntry
): Promise<void> => {
    const { logLevelClient, console } = context.config;
    const {
        properties,
        moduleName,
        functionName,
        skipServerLogging,
        allowDuplicates,
    } = entry;

    const exception = await resolveException(entry);

    const postLogRequest: PostLogRequest = {
        level: level ?? LogLevel.Information,
        ...getMessageTemplate(entry, exception),
        properties: getLogProperties(
            context,
            moduleName,
            functionName,
            properties
        ),
        exception,
    };

    logClient(postLogRequest, { logLevelClient, console });

    if (skipServerLogging) {
        return;
    }

    logServer(context, postLogRequest, allowDuplicates === true);
};

const createLogFunction =
    (context: LogContext, logLevel: LogLevel) => (entry: LogEntry) =>
        log(context, logLevel, entry);

const event = (context: LogContext, eventLog: EventLogEntry): void => {
    const { logLevelClient, apiHost, console } = context.config;
    const {
        type,
        messageTemplate,
        messageTemplateParameters,
        properties,
        moduleName,
        functionName,
    } = eventLog;

    const postEventRequest: PostEventRequest = {
        type,
        messageTemplate,
        messageTemplateParameters,
        properties: getLogProperties(
            context,
            moduleName,
            functionName,
            properties
        ),
    };

    logClient(
        {
            level: LogLevel.Information,
            messageTemplate: `${type} - ${messageTemplate}`,
            messageTemplateParameters,
        },
        {
            logLevelClient,
            prefix: 'event',
            console,
        }
    );

    post({
        ...getRequestPayload(postEventRequest),
        apiHost,
        path: 'event',
    });
};

export const createLog = (config: LogConfig): Log => {
    const context = createLogContext(config);

    return {
        log: (level: LogLevel, entry: LogEntry) => log(context, level, entry),
        debug: createLogFunction(context, LogLevel.Debug),
        info: createLogFunction(context, LogLevel.Information),
        warn: createLogFunction(context, LogLevel.Warning),
        error: createLogFunction(context, LogLevel.Error),
        fatal: createLogFunction(context, LogLevel.Fatal),
        event: (eventLog: EventLogEntry) => event(context, eventLog),
        registerLogEnricher: (enricher: () => LogProperties) => {
            context.enricher = enricher;
        },
        overrideApiHost: (token: Token, apiHost: Uri) => {
            context.apiHostOverrides[token] = apiHost;
        },
    };
};
